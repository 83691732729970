import { type ThemeOptions } from '@mui/material/styles';
import { Poppins } from 'next/font/google';
import { baseTheme } from './base';

const poppins = Poppins({
	display: 'swap',
	subsets: ['latin'],
	weight: ['400', '300', '500', '600'],
});

const darkConfig = {
	...baseTheme,
	palette: {
		divider: '#646464',
		mode: 'dark',
		primary: {
			main: '#00EAB7',
			contrastText: '#000',
			dark: '#00C298',
			light: '#99FFE9',
		},
		secondary: {
			main: '#151519ff',
			contrastText: '#fff',
			dark: '#000000',
			light: '#393943',
		},

		text: {
			primary: '#ffffff',
			action: '#7FBEF6',
			contrast: '#000000',
			disabled: '#ABABAB',
			secondary: '#ffffff',
			subheader: '#B3B3B3',
			subtext: '#BEBEBE',
		},
		warning: {
			main: '#FFC107',
			contrastText: '#000000',
			dark: '#ff6f00',
			light: '#ff9e80',
		},
		error: {
			main: '#CD0000',
			contrastText: '#fff',
			dark: '#990000',
			light: '#FFEAEA',
		},
		background: {
			default: '#141414',
			assets: '#5E5E62',
			border: '#444',
			cards: '#292930',
			chip: '#303030',
			dark: 'rgba(0, 0, 0, 0.30)',
			info: '#2C2C33',
			offBlack: '#141414',
			paper: '#2C2C33',
			search: '#181818',
			text: '#1F1F1F',
		},
		action: {
			disabled: '#606060',
		},
		original: {
			main: '#00eab7',
			contrastText: '#000',
			light: '#b1ffee',
		},
		icons: {
			active: '#FFE456',
			drawer: '#1F1F1F',
			edit: '#00624D',
			greyed: '#6E6E6E',
			home: '#76768F',
			inactive: '#fff',
		},
		assetOverlay: {
			bg: '#000000',
			bgTransparent: '#000000be',
		},
		gradient: {
			main: 'linear-gradient(126deg, #0EEBBB 0%, #F90 100%)',
			hover: 'linear-gradient(180deg, #00EAB7 0%, #00EAB7 100%)',
			error: 'linear-gradient(270deg, #FA554A 0%, #FA7F4A 100%)',
			home: 'linear-gradient(180deg, #19191D10 0%, #00352A 100%)',
			modal: 'linear-gradient(180deg, #19191D75 0%, #00352A 100%)',
			private: 'linear-gradient(90deg, #FFE456 0%, #FF9900 100%)',
		},
		badge: {
			notification: '#990000',
		},
		step: {
			active: '#00E7AF',
			completed: '#303030',
		},
		beta: {
			text: '#07DE47',
		},
		borders: {
			dark: '#444',
		},
	},
	typography: {
		fontFamily: poppins.style.fontFamily,
	},
} satisfies ThemeOptions;

export const darkTheme = darkConfig;
