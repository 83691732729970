/* eslint-disable no-return-assign */

/* eslint-disable react/display-name */
import { type StackProps, TextField, type TextFieldProps } from '@mui/material';
import React, { type ReactElement, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

const allowedCharactersValues = ['alpha', 'numeric', 'alphanumeric'] as const;

export type CodeInputProps = {
	allowedCharacters?: (typeof allowedCharactersValues)[number];
	ariaLabel?: string;
	autoFocus?: boolean;
	containerClassName?: string;
	disabled?: boolean;
	inputClassName?: string;
	isPassword?: boolean;
	length?: number;
	placeholder?: string;
	containerSx?: StackProps['sx'];
	inputStyles?: TextFieldProps['style'];
	onChange: (res: string) => void;
	error: boolean;
};

type InputMode = 'text' | 'numeric';

type InputType = 'text' | 'tel' | 'password';

type InputProps = {
	type: InputType;
	inputMode: InputMode;
	pattern: string;
	min?: string;
	max?: string;
};

export type CodeInputRef = {
	focus: () => void;
	clear: () => void;
};

const propsMap: { [key: string]: InputProps } = {
	alpha: {
		type: 'text',
		inputMode: 'text',
		pattern: '[a-zA-Z]{1}',
	},

	alphanumeric: {
		type: 'text',
		inputMode: 'text',
		pattern: '[a-zA-Z0-9]{1}',
	},

	numeric: {
		type: 'tel',
		inputMode: 'numeric',
		pattern: '[0-9]{1}',
		min: '0',
		max: '9',
	},
};

const CodeInput = forwardRef<CodeInputRef, CodeInputProps>(
	(
		{
			allowedCharacters = 'alphanumeric',
			ariaLabel,
			autoFocus = true,
			disabled,
			inputClassName,
			isPassword = false,
			length = 6,
			placeholder,
			onChange,
			error,
			inputStyles,
		},
		ref,
	) => {
		if (Number.isNaN(length) || length < 1) {
			throw new Error('Length should be a number and greater than 0');
		}

		if (!allowedCharactersValues.some((value) => value === allowedCharacters)) {
			throw new Error('Invalid value for allowedCharacters. Use alpha, numeric, or alphanumeric');
		}

		const inputsRef = useRef<Array<HTMLInputElement>>([]);
		const inputProps = propsMap[allowedCharacters];

		const sendResult = () => {
			const res = inputsRef.current.map((input) => input.value).join('');
			if (onChange) {
				onChange(res);
			}
		};

		useImperativeHandle(ref, () => ({
			focus: () => {
				if (inputsRef.current) {
					inputsRef.current[0].focus();
				}
			},
			clear: () => {
				if (inputsRef.current) {
					for (let i = 0; i < inputsRef.current.length; i += 1) {
						inputsRef.current[i].value = '';
					}
					inputsRef.current[0].focus();
				}
				sendResult();
			},
		}));

		useEffect(() => {
			if (autoFocus) {
				inputsRef.current[0].focus();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			const {
				target: { value, nextElementSibling: nelement },
			} = e;
			const nextElementSibling =
				nelement?.parentElement?.parentElement?.nextElementSibling?.getElementsByTagName('input')?.[0];

			if (value.length > 1) {
				e.target.value = value.charAt(0);
				if (nextElementSibling) {
					nextElementSibling.focus();
				}
			} else if (value.match(inputProps.pattern)) {
				if (nextElementSibling) {
					nextElementSibling.focus();
				}
			} else {
				e.target.value = '';
			}
			sendResult();
		};

		const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
			const { key } = e;
			const target = e.target as HTMLInputElement;
			if (key === 'Backspace') {
				// If the current input is already empty, focus on the previous input
				if (target.value === '') {
					const prevElementSibling =
						target?.parentElement?.parentElement?.previousElementSibling?.getElementsByTagName(
							'input',
						)?.[0];
					if (prevElementSibling) {
						prevElementSibling.value = '';
						prevElementSibling.focus();
						e.preventDefault();
					}
				} else {
					// Else clear current focused input
					target.value = '';
				}
				sendResult();
			}
			if (key === 'Delete') {
				target.value = '';
				sendResult();
			}
			if (key === 'ArrowRight') {
				const nextElementSibling =
					target?.parentElement?.parentElement?.nextElementSibling?.getElementsByTagName('input')?.[0];
				if (nextElementSibling) {
					nextElementSibling.focus();
				}
			}
			if (key === 'ArrowLeft') {
				const prevElementSibling =
					target?.parentElement?.parentElement?.previousElementSibling?.getElementsByTagName('input')?.[0];
				if (prevElementSibling) {
					prevElementSibling.focus();
				}
			}
		};

		const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
			e.target.select();
		};

		const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
			const pastedValue = e.clipboardData.getData('Text');
			let currentInput = 0;
			for (let i = 0; i < pastedValue.length; i += 1) {
				const pastedCharacter = pastedValue.charAt(i);
				const currentInputRef = inputsRef.current[currentInput];
				if (currentInputRef && pastedCharacter.match(inputProps.pattern)) {
					const currentValue = currentInputRef.value;
					if (!currentValue) {
						currentInputRef.value = pastedCharacter;
						const nextInputRef = currentInputRef.nextElementSibling;
						if (nextInputRef !== null) {
							(nextInputRef as HTMLInputElement).focus();
							currentInput += 1;
						}
					}
				}
			}
			sendResult();
			e.preventDefault();
		};

		const inputs: Array<ReactElement> = [];
		for (let i = 0; i < length; i += 1) {
			inputs.push(
				<TextField
					key={`auth-code-input-${i}`}
					error={error}
					inputRef={(el) => (inputsRef.current[i] = el as HTMLInputElement)}
					max
					onChange={handleOnChange}
					onFocus={handleOnFocus}
					onKeyDown={handleOnKeyDown}
					onPaste={handleOnPaste}
					{...inputProps}
					aria-label={ariaLabel ? `${ariaLabel}. Character ${i + 1}.` : `Character ${i + 1}.`}
					autoComplete={i === 0 ? 'one-time-code' : 'off'}
					className={inputClassName}
					disabled={disabled}
					inputProps={{
						maxLength: 1,
						style: {
							textAlign: 'center',
							...inputStyles,
						},
					}}
					placeholder={placeholder}
					sx={{
						mx: 1,
					}}
					type={isPassword ? 'password' : inputProps.type}
				/>,
			);
		}

		// eslint-disable-next-line react/jsx-no-useless-fragment
		return <>{inputs}</>;
	},
);

CodeInput.defaultProps = {
	allowedCharacters: 'alphanumeric' as const,
	ariaLabel: undefined,
	autoFocus: true,
	containerClassName: undefined,
	disabled: false,
	inputClassName: undefined,
	isPassword: false,
	length: 6,
	placeholder: undefined,
	containerSx: undefined,
	inputStyles: undefined,
};

export default CodeInput;
