import { type ThemeOptions } from '@mui/material';
import { Poppins } from 'next/font/google';

const poppins = Poppins({
	display: 'swap',
	subsets: ['latin'],
	style: ['normal', 'italic'],
	weight: ['300', '400', '500', '600', '700', '800', '900'],
	preload: true,
});

export const baseTheme: ThemeOptions = {
	breakpoints: {
		values: {
			// Only changing sm to 500, rest default
			xs: 0,
			sm: 500,
			md: 900,
			lg: 1200,
			xl: 1536,
		},
	},
	components: {
		MuiAvatar: {
			styleOverrides: {
				root: {
					maxHeight: '45px',
					maxWidth: '45px',
				},
			},
		},
		MuiBackdrop: {
			styleOverrides: {
				root: {
					margin: 'auto !important',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 28,
					textTransform: 'none',
				},
			},
		},
		MuiCard: {
			defaultProps: {
				elevation: 2,
			},
			styleOverrides: {
				root: {
					backgroundImage: 'none',
					borderRadius: 18,
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					color: '#fff',
				},
			},
		},

		MuiChip: {
			styleOverrides: {
				root: {
					borderRadius: 28,
				},
			},
		},
		MuiContainer: {
			styleOverrides: {
				root: {
					margin: 'auto auto 3.2rem',
					maxWidth: '100%',
					padding: '0 !important',
					position: 'relative',
				},
			},
			defaultProps: {
				maxWidth: false,
			},
		},
		MuiCssBaseline: {
			'@global': {
				'.Mui-disabled': {
					color: '#646464',
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: '28px !important',
					padding: 12,
				},
			},
		},
		MuiDropzoneArea: {
			styleOverrides: {
				root: {
					backgroundColor: 'rgba(0, 234, 183, 0.274)',
					borderColor: '#00EAB7',
					borderRadius: 28,
					'&:hover': {
						backgroundColor: 'rgba(0, 234, 183, 0.486)',
					},
					'&.MuiDropzoneArea-active': {
						animation: 'gradient 1s ease infinite !important',
						backgroundColor: 'rgba(0, 234, 183, 0.443)',
						backgroundImage: 'linear-gradient(45deg, #128f7436, #208980da)',
					},

					'&.MuiDropzoneArea-invalid': {
						backgroundImage: 'linear-gradient(45deg, #dc260aa1, #892020a6) !important',
					},
				},
			},
		},
		MuiDropzonePreviewList: {
			styleOverrides: {
				root: {
					paddingLeft: 8,
					'& .MuiChip-root': {
						backgroundColor: '#000000',
					},
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					borderRadius: 28,
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: '#fff',
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				paper: {
					backgroundColor: 'background.paper',
					borderRadius: '20px !important',
					outline: '1px solid background.paper',
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					'&:hover': {
						transition: 'all 0.1s ease-in-out',
					},
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: 28,
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-notchedOutline': {
						border: 0,
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						border: 0,
					},
					'&:hover .MuiOutlinedInput-notchedOutline': {
						border: 0,
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-root': {
						backgroundColor: '#1F1F1F',
						borderRadius: 10,
						'&.Mui-focused fieldset': {
							borderColor: 'primary.main',
						},
					},
				},
			},
			variants: [
				{
					props: { className: 'search-bar' },
					style: {
						backgroundColor: '#4e4e5f',
					},
				},
			],
		},
		MuiTooltip: {
			styleOverrides: {
				popper: {
					paddingRight: '0.4%',
				},
				tooltip: {
					backgroundColor: '#212121',
					border: '1px solid #444',
					borderRadius: '12px',
					boxShadow: '-16px 16px 20px 0px rgba(0, 0, 0, 0.25)',
					fontSize: '1rem',
					marginRight: '7px !important',
					maxWidth: '400px',
					minWidth: '20%',
					width: '100%',
				},
				arrow: {
					color: '#212121',
					'&::before': {
						border: '1px solid #444',
						boxShadow: '-16px 16px 20px 0px rgba(0, 0, 0, 0.25)',
					},
				},
			},
		},
	},
	shape: { borderRadius: 4 },
	typography: {
		fontFamily: [
			poppins.style.fontFamily,
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		fontWeightRegular: 400,
	},
	palette: {},
};
