/* eslint-disable no-nested-ternary */
import { type PublicAsset } from '@mpx-sdk/types';
import { OverlayLoading } from '@mpx-sdk/ui/components/core';
import { ErrorOutlineRounded } from '@mui/icons-material';
import { Box, Grid, LinearProgress } from '@mui/material';
import { type ReactElement, memo } from 'react';

interface MPXGOverlayProps {
	/** Type of click event to trigger */
	clickType?: 'download' | 'info' | 'delete';
	/** Icon to display */
	icon?: ReactElement;
	/** Click event handler */
	onClick?: (type?: 'download' | 'info' | 'delete') => void;
	/** Text to display */
	text?: string;
	/** What type of overlay to display */
	type: 'processing' | 'failed' | 'complete';
	projectData?: PublicAsset;
}

/** A component that displays an overlay content with an icon and text. */
function MPXGOverlay({
	clickType,
	onClick,
	text,
	type = 'complete',
	projectData,
}: Readonly<MPXGOverlayProps>): ReactElement | null {
	if (type === 'complete') {
		return null;
	}

	return (
		<Grid
			key={`asset-media-overlay-content-${text}-${clickType}`}
			aria-label={`${clickType} button`}
			className='asset-media-overlay-content'
			item
			onClick={(e) => {
				e.preventDefault();
			}}
			onKeyDown={(event) => {
				event.preventDefault();
			}}
			sx={{
				backgroundColor: type === 'failed' ? '#2F1010 !important' : 'assetOverlay.bgTransparent',
				color: type === 'failed' ? '#EB5050 !important' : 'primary.main',
				cursor: onClick ? 'pointer' : 'default',
				fontSize: `clamp(0.6rem, 1.05rem, 1.3rem) !important`,
				height: '100%',
				opacity: 0.5,
				padding: '0.5rem',
				svg: { color: type === 'failed' ? '#EB5050 !important' : 'primary.main' },
			}}
			xs={12}
		>
			{type === 'failed' ? (
				<Box
					sx={{
						fontSize: '2.5rem',
					}}
				>
					<ErrorOutlineRounded />
				</Box>
			) : (
				<Box
					sx={{
						height: '100%',
						width: '100%',
					}}
				>
					<OverlayLoading />

					{projectData && (
						<LinearProgress
							value={
								projectData.metadata?.genAIData?.progress !== 100 &&
								projectData.metadata?.genAIData?.progress >= 0
									? projectData.metadata?.genAIData?.progress
									: 0
							}
							variant={
								projectData.metadata?.genAIData?.progress !== 100 &&
								projectData.metadata?.genAIData?.progress >= 0
									? 'determinate'
									: 'indeterminate'
							}
						/>
					)}
				</Box>
			)}
		</Grid>
	);
}

export default memo(MPXGOverlay);
