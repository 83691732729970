import { downloadProjectFiles, toggleLikeProject } from '@mpx-sdk/helpers/assets';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { BookmarkIcon, InfoIcon, LoadIcon, OpenIcon, RemixIcon } from '@mpx-sdk/images';
import { inAppBrowserAtom, singleAssetViewAtom, userAtom } from '@mpx-sdk/shared/atoms';
import { type PublicAsset } from '@mpx-sdk/types';
import MPXGOverlay from '@mpx-sdk/ui/components/assets/card/overlay/MPXGOverlay';
import OverlayContent from '@mpx-sdk/ui/components/assets/card/overlay/OverlayContent';
import OverlayMoreContent from '@mpx-sdk/ui/components/assets/card/overlay/OverlayMoreContent';
import DeleteAsset from '@mpx-sdk/ui/components/delete-asset/DeleteAsset';
import { Cached as CachedIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { debounce, isEmpty, isNumber } from 'lodash';
import { useRouter } from 'next/navigation';
import { type ReactElement, memo, useEffect, useMemo, useState } from 'react';

interface AssetCardOverlayProps {
	/** Whether to show the delete asset button */
	deleteAsset?: boolean;
	/** The project data to display in the modal */
	projectData: PublicAsset;
}

/** A component that displays an overlay with action buttons for a project asset. */
function AssetCardOverlay({ deleteAsset, projectData }: AssetCardOverlayProps): ReactElement | null {
	const [displayMore, setDisplayMore] = useState(false);
	const [isBookmarked, setIsBookmarked] = useState(projectData?.bookmarked ?? false);
	const [loadingStatus, setLoadingStatus] = useState(false);
	const currentUser = useAtomValue(userAtom);
	const inApp = useAtomValue(inAppBrowserAtom);
	const responsiveView = UIHelper.isResponsiveView();
	const router = useRouter();
	const setSAVProjectData = useSetAtom(singleAssetViewAtom);
	const { category, tags, path, id } = projectData ?? {};
	const displayGenAIOverlay =
		projectData?.metadata?.genAIData?.status && !['complete'].includes(projectData?.metadata?.genAIData?.status);

	/** The options for the overlay actions. */
	const displayOptions: any = useMemo(
		() => ({
			loading: {
				icon: <CachedIcon />,
				text: 'LOADING...',
			},
			info: {
				clickType: 'info',
				href: typeof id === 'number' ? `/library/${id}` : '',
				icon: <InfoIcon />,
				text: 'INFO',
			},
			remix: {
				clickType: 'download',
				icon: inApp ? <RemixIcon /> : <OpenIcon />,
				text: 'REMIX',
			},
			load: {
				clickType: 'download',
				icon: inApp ? <OpenIcon /> : <LoadIcon />,
				text: inApp ? 'LOAD' : 'DOWNLOAD',
			},
			open: {
				clickType: 'download',
				icon: <OpenIcon />,
				text: 'OPEN',
			},
			delete: {
				clickType: 'delete',
			},
			more: {
				clickType: 'more',
				icon: <MoreVertIcon />,
				text: 'MORE',
			},
			bookmark: {
				clickType: 'bookmark',
				icon: <BookmarkIcon className='asset-card-overlay-bookmark-icon' />,
				text: 'BOOKMARK',
			},
		}),
		[id, inApp],
	);

	const handleBookmarkProject = async () => {
		const newBookmarkState = !isBookmarked;
		setIsBookmarked(newBookmarkState);
		projectData = {
			...projectData,
			bookmarked: newBookmarkState,
		};

		toggleLikeProject(Number(projectData?.id), newBookmarkState);
	};

	/** Calls the onClick function with the specified click type. */
	const handleClickingProject = async (clickType = inApp ? 'download' : 'info') => {
		if (clickType === 'more') {
			setDisplayMore(true);
			return;
		}

		if (clickType === 'bookmark') {
			handleBookmarkProject();
			return;
		}

		if (clickType === 'download') {
			setLoadingStatus(true);

			await downloadProjectFiles(projectData, inApp);

			setLoadingStatus(false);
		}

		if (clickType === 'info') {
			setSAVProjectData(projectData);

			const newLink = isNumber(projectData?.id)
				? `/library/${
						!isEmpty(projectData.title) ? `${projectData.title.trim().replace(/[\s\W-]+/g, '-')}-` : ''
					}${projectData.id}`
				: `/storage/${projectData?.id}`;

			router.push(newLink, {
				shallow: true,
			});
		}
	};

	const debounceHandleClickingProject = debounce(handleClickingProject, 500);

	const [toDisplay, setToDisplay] = useState<any>([]);

	/** Updates the actions to display based on the project data. */
	function updateToDisplay(
		/** The action to display. */
		whichToDisplay?,
	) {
		const toDisplay: any = [];

		// Add which import type to display
		if (whichToDisplay) {
			toDisplay.push(whichToDisplay);
		}

		// Add the info button
		if (inApp && !projectData.destination) {
			toDisplay.push(displayOptions.info);
		}

		// Add the delete button
		if (
			deleteAsset &&
			(currentUser?.id === projectData?.user?.id || currentUser?.id === projectData?.owner?.id) &&
			typeof projectData?.id !== 'number'
		) {
			toDisplay.push(displayOptions.delete);
		}

		// Add the bookmark button
		if (typeof projectData?.id === 'number' && currentUser?.id !== projectData?.user?.id && !inApp) {
			toDisplay.push(displayOptions.bookmark);
		}

		// Add the more button
		if (inApp && typeof projectData?.id === 'number' && !projectData.destination) {
			toDisplay.push(displayOptions.more);
		}

		// Update display
		setToDisplay(toDisplay);
	}

	/** Sets up the media overlay based on the project data. */
	function setUpMediaOverlay(): void {
		if (!projectData || (!inApp && !currentUser)) {
			return;
		}

		if (projectData.destination || (!inApp && !Number.isNaN(projectData.id) && projectData?.path)) {
			updateToDisplay();
		} else if (inApp) {
			if (category === 'model') {
				if (tags?.includes('stamp')) {
					updateToDisplay(displayOptions.load);
				} else {
					updateToDisplay(displayOptions.remix);
				}
			} else if (path) {
				updateToDisplay(displayOptions.open);
			} else if (category) {
				updateToDisplay(displayOptions.load);
			}
		} else {
			updateToDisplay(displayOptions.load);
		}
	}

	useEffect(() => {
		if (inApp && loadingStatus) {
			// Media overlay to loading...
			setToDisplay([displayOptions.loading]);
			// After 30 seconds, display media overlay
			setTimeout(() => {
				setLoadingStatus(false);
				setUpMediaOverlay();
			}, 30000);
		} else if (!loadingStatus) {
			setUpMediaOverlay();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inApp, loadingStatus]);

	useEffect(() => {
		setUpMediaOverlay();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box
			className='asset-card-overlay'
			sx={{
				display: displayGenAIOverlay ? 'block !important' : 'none',
				'.asset-media-overlay': {
					height: displayGenAIOverlay ? '100%' : 'initial',
				},
			}}
		>
			{displayMore ? (
				<OverlayMoreContent
					onClose={() => {
						setDisplayMore(false);
					}}
					projectData={projectData}
				/>
			) : (
				<Grid
					alignItems={toDisplay.length === 2 ? 'flex-start' : 'stretch'}
					className='asset-media-overlay asset-media-overlay-buttons'
					container
					direction='row'
					justifyContent='flex-end'
					spacing={0}
					sx={
						!responsiveView && !displayGenAIOverlay
							? {
									'.asset-media-overlay-content': {
										backgroundColor: 'assetOverlay.bgTransparent',
										'&:hover': {
											backgroundColor: 'primary.main',
											color: 'assetOverlay.bg',
											svg: { color: 'assetOverlay.bg' },
										},
									},
									flexWrap: 'wrap-reverse',
									minHeight: toDisplay.length >= 3 ? '100%' : 'initial',
									'.asset-card-overlay-bookmark-icon': {
										color: (theme) =>
											isBookmarked
												? `${theme.palette.icons.active} !important`
												: `${theme.palette.icons.inactive} !important`,
									},
								}
							: {
									flexDirection: 'row !important',
									flexWrap: 'wrap-reverse',
									svg: { color: 'icons.inactive' },
									'.asset-card-overlay-bookmark-icon': {
										color: (theme) =>
											isBookmarked
												? `${theme.palette.icons.active} !important`
												: `${theme.palette.icons.inactive} !important`,
									},
								}
					}
				>
					{displayGenAIOverlay ? (
						<MPXGOverlay projectData={projectData} type={projectData?.metadata?.genAIData?.status} />
					) : (
						<>
							{toDisplay.map((displayData) => {
								if (displayData?.clickType === 'delete') {
									return (
										<DeleteAsset
											key={`delete-asset-overlay-${id}`}
											projectData={projectData}
											stylingType='overlay'
										/>
									);
								}

								return (
									<OverlayContent
										key={`overlay-content-${displayData?.clickType}`}
										aria-label={displayData?.text}
										clickType={displayData?.clickType}
										icon={displayData?.icon}
										numberOfOverlayContent={toDisplay.length}
										onClick={() => {
											debounceHandleClickingProject(displayData?.clickType);
										}}
										text={displayData?.text}
									/>
								);
							})}
						</>
					)}
				</Grid>
			)}
		</Box>
	);
}

export default memo(AssetCardOverlay);
