/* eslint-disable react/jsx-no-duplicate-props */
import IconTooltip, { type ToolTipProps } from '@mpx-sdk/ui/components/tooltips/IconTooltip';
import { FormLabel, Stack, TextField, type TextFieldProps, Typography } from '@mui/material';
import { type ReactNode } from 'react';
import { Field } from 'react-final-form';
import GradientTypography from '../GradientTypography';

export const defaultMaxLength = 300;

export default function FormField({
	name,
	helperText = '',
	validators,
	title = '',
	hideCharacterCount = false,
	tooltip,
	infoTooltip = undefined,
	helperButton,
	maxLength = defaultMaxLength,
	maxWords,
	interactive = false,

	...props
}: {
	name: string;
	validators?: any;
	title: string;
	helperButton?: React.ReactNode;
	hideCharacterCount?: boolean;
	infoTooltip?: ToolTipProps;
	maxLength?: number;
	maxWords?: number;
	interactive?: boolean;
	tooltip?: {
		title?: string;
		content: (string | ReactNode)[];
	};
} & TextFieldProps) {
	return (
		<Field<string>
			key={`field-${name}`}
			name={name}
			render={({ input, meta }) => (
				<Stack key={`stack-${name}`} spacing={2}>
					<FormLabel htmlFor={`text-${name}`} sx={{ display: 'block' }}>
						<Stack direction='row'>
							<Typography dangerouslySetInnerHTML={{ __html: title }} noWrap={false} />

							{infoTooltip && <IconTooltip {...infoTooltip} />}
						</Stack>
					</FormLabel>

					<IconTooltip
						interactive={interactive}
						paragraphs={tooltip?.content ?? []}
						showCloseButton
						title={tooltip?.title ?? ''}
					>
						<TextField
							multiline
							{...props}
							{...input}
							error={meta.error && meta.touched}
							helperText={meta.error && meta.touched ? meta.error || meta.submitError : helperText}
							id={`text-${name}`}
							inputProps={{
								...props.inputProps,
								maxLength,
							}}
							InputProps={{
								...props.InputProps,
								style: {
									height: '100%',
									...props.InputProps?.style, // Preserve other styles
								},
								endAdornment: (
									<Stack alignItems='center' direction='row' spacing={1}>
										{helperButton && <div>{helperButton}</div>}
										{!hideCharacterCount && (
											<GradientTypography
												sx={{
													bottom: '3.5%',
													color: '#808080',
													fontSize: '0.75rem',
													position: 'absolute',
													right: '3.5%',
												}}
												variant='caption'
											>
												{(input.value as any).length}/ {maxLength.toString()}
											</GradientTypography>
										)}
									</Stack>
								),
							}}
							variant='outlined'
						/>
					</IconTooltip>
				</Stack>
			)}
			validate={validators}
		/>
	);
}
