'use client';

import { accessTokenProvider } from '@api-client/index';
import { CookieNames } from '@mpx-sdk/shared/configs';
import { isBrowser } from '@mpx-sdk/shared/utils';
import { getCookie } from 'cookies-next';
import { SessionProvider as NextSessionProvider } from 'next-auth/react';
import { type PropsWithChildren } from 'react';

function SessionProvider({ children, session }: PropsWithChildren<any>) {
	accessTokenProvider.extractJwtFrom(async () => session?.accessToken || (isBrowser() && getCookie(CookieNames.JWT)));

	return <NextSessionProvider session={session}>{children}</NextSessionProvider>;
}

export default SessionProvider;
