import { Skeleton, Stack } from '@mui/material';
import Image, { type ImageProps } from 'next/image';
import { useEffect, useState } from 'react';

interface ImageWithFallbackProps extends ImageProps {
	/** The source of the fallback image to use when the main image fails to load. */
	fallbackSrc: string;
}

/** A component that displays an image with a fallback image and a loading skeleton. */
function ImageWithFallback(props: ImageWithFallbackProps) {
	const { fallbackSrc, loading: isLoading, onError, src, style, ...rest } = props;
	const [imgSrc, setImgSrc] = useState(src);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (src) {
			setImgSrc(src);
			setLoading(false);
		}
	}, [src]);

	return (
		<Stack sx={{ position: 'relative' }}>
			{/* eslint-disable-next-line jsx-a11y/alt-text */}
			<Image
				loading='lazy'
				onError={(e) => {
					setImgSrc(fallbackSrc);
					onError?.(e);
				}}
				onLoad={() => {
					setLoading(false);
				}}
				src={imgSrc || fallbackSrc}
				style={style}
				{...rest}
			/>
			{(loading || isLoading) && <Skeleton height='100%' variant='rectangular' width='100%' />}
		</Stack>
	);
}

export default ImageWithFallback;
