import { Box, CircularProgress } from '@mui/material';

interface OverlayLoadingProps {
	bg?: string;
	text?: string;
	textPosition?: 'top' | 'bottom' | 'left' | 'right';
}

export default function OverlayLoading({ bg, text, textPosition }: Readonly<OverlayLoadingProps>) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexGrow: 1,
				width: '100%',
				height: '100%',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'absolute',
				bgcolor: bg,
				flexDirection: textPosition === 'right' || textPosition === 'left' ? 'row' : 'column',
			}}
		>
			{text && (textPosition === 'top' || textPosition === 'left') && text}
			<CircularProgress />
			{text && (textPosition === 'bottom' || textPosition === 'right') && text}
		</Box>
	);
}
