import { InputBase, InputLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { type InputBaseProps } from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
	'label + &': {
		marginTop: theme.spacing(3),
	},

	'& .MuiInputBase-input': {
		borderRadius: 28,
		position: 'relative',
		backgroundColor: theme.palette.mode === 'light' ? '#F0F1F7' : '#474646',
		border: '0',
		fontSize: 16,
		width: '100%',
		padding: '10px 12px',
		transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),

		'&:focus': {
			boxShadow: `${alpha(theme.palette.primary.main, 0.75)} 0 0 0 0.015rem`,
			borderColor: theme.palette.primary.main,
		},
	},
}));

export function TextField({ label, fullWidth, ...rest }: InputBaseProps & { fullWidth?: boolean; label?: string }) {
	return (
		<FormControl fullWidth={fullWidth} variant='standard'>
			<InputLabel htmlFor={label} shrink>
				{label}
			</InputLabel>

			<BootstrapInput {...rest} id={label} />
		</FormControl>
	);
}
