import classNames from 'classnames';

type Props = {
	className?: string;
	children: React.ReactNode;
};

/** Main Purpose: This component should center the data vertically and horizontally on page. */
export default function Page({ className, children }: Props) {
	return <div className={classNames('mps-page', className)}>{children}</div>;
}
