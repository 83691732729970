'use client';

import { BackIcon } from '@mpx-sdk/images';
import { inAppBrowserAtom } from '@mpx-sdk/shared/atoms';
import { IconButton, Tooltip } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

/** A component that displays a button to scroll back to the top of the page. */
export default function BackToTop() {
	const searchParams = useSearchParams();

	const [addedEventListeners, setAddedEventListeners] = useState(false);
	const [showButton, setShowButton] = useState(false);
	const inApp = useAtomValue(inAppBrowserAtom);
	const phaseDependentState = searchParams.get('f');

	const handleClick = () => {
		if (typeof window !== 'undefined') {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		} else if (document?.getElementById('content')) {
			document.getElementById('content')?.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}

		setShowButton(false);
	};

	function handleScroll() {
		if (typeof window !== 'undefined') {
			setShowButton(window.scrollY > 200);
		}
	}

	useEffect(() => {
		handleScroll();

		if (typeof window !== 'undefined' && !addedEventListeners) {
			setAddedEventListeners(true);

			window.addEventListener('scroll', handleScroll);
			window.addEventListener('resize', handleScroll);

			return () => {
				window.removeEventListener('scroll', handleScroll);
				window.removeEventListener('resize', handleScroll);
			};
		}

		return () => {};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Tooltip arrow describeChild title='Back to top of page'>
			<IconButton
				aria-label='Back to top of page'
				data-testid='back-to-top-button'
				id='backToTop'
				onClick={handleClick}
				sx={{
					backgroundColor: '#fff',
					borderRadius: '50%',
					bottom: inApp && !phaseDependentState ? '6rem' : '2rem',
					opacity: showButton ? 1 : 0,
					pointerEvents: showButton ? 'auto' : 'none',
					position: 'fixed',
					right: '3rem',
					transition: 'opacity 0.5s ease-in-out',
					zIndex: 1000,
					svg: {
						color: '#000',
						// Flip 90 degrees
						transform: 'rotate(90deg)',
					},
					'&:hover': {
						backgroundColor: 'primary.main',
					},
				}}
				type='button'
			>
				<BackIcon />
			</IconButton>
		</Tooltip>
	);
}
