'use client';

import { store } from '@mpx-sdk/shared/atoms';
import { Provider } from 'jotai';
import { type PropsWithChildren } from 'react';

function JotaiProvider({ children }: PropsWithChildren<any>) {
	return (
		<Provider store={typeof window !== 'undefined' ? (store as any) : undefined}>
			{/* <DevTools store={store} /> */}
			{children}
		</Provider>
	);
}

export default JotaiProvider;
