import { newLibraryState } from '@mpx-sdk/helpers/library';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { libraryActiveSorting, libraryURLParamsAtom } from '@mpx-sdk/shared/atoms';
import { Select, type SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useAtom, useSetAtom } from 'jotai';
import { startCase } from 'lodash';
import { useRouter } from 'next/navigation';
import { type ReactElement, useCallback, useEffect } from 'react';

interface AssetSortProps {
	/** The default sort to display (must be in props.sortOptions or something like "Newest", "Featured", etc...) - case sensitive [default: "Trending"][Optional] */
	sortBy?: string;
	/** The options to display in the dropdown menu [Optional] */
	sortOptions?: string[];
	/** The function to call when a sort option is selected */
	sortAction?: (sort: string) => void;
	/** The classes to apply to the component */
	className?: string | string[];
	/** The sort to display in the dropdown menu */
	sortFor?: string;
	defaultSort?: string;
}

/**
 * Create JSX component for the sort dropdown menu in the library
 * @param {Object} props The props passed to the component including
 * @returns {JSX} JSX for the sort dropdown menu
 */
export default function AssetSort({
	sortBy = 'Newest',
	sortOptions = [
		'Trending',
		'Newest',
		'Oldest',
		'Last Updated',
		'Alphabetically',
		'Most Viewed',
		'Most Remixed',
		'Most Downloaded',
	],
	sortAction,
	className,
	sortFor = 'library',
	defaultSort,
}: AssetSortProps): ReactElement {
	const router = useRouter();
	const { query } = router;
	const querySort = query?.s ? query.s.toString().trim() : undefined;

	/** The sorting option to display */
	const [sort, setSort] = useAtom(libraryActiveSorting);
	const setLibraryURLParams = useSetAtom(libraryURLParamsAtom);

	const defaultSorting = querySort || defaultSort || sortOptions?.[0] || 'Newest';

	/** Class names for the sorting component */
	let classNameList: string[] = ['asset-sort-select'];
	if (className) {
		if (typeof className === 'string') {
			classNameList = [...classNameList, ...className.split(' ')];
		} else if (Array.isArray(className)) {
			classNameList = [...classNameList, ...className];
		}

		// Remove duplicates
		classNameList = Array.from(new Set(classNameList));
	}

	/** Handle the sort option being selected/changed */
	const handleSort = useCallback(
		(event: SelectChangeEvent<string>) => {
			const { value } = event.target;
			if (value !== sort) {
				// Update the sort state
				setSort(value);

				// Call the sort action
				sortAction?.(value);

				// Update the library URL params
				const params = value !== defaultSorting ? { s: value } : { s: '' };
				setLibraryURLParams(newLibraryState(params) ?? {});

				// Track the sort event
				DataLayer.triggerMeasurementEvent('galleryEvent', {
					event_name: 'gallery_sort',
					sort_option: value ?? '',
					filter_option: '',
					query: '',
					tag: '',
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[sort, defaultSorting],
	);

	useEffect(() => {
		// Set-up the sort value
		if (querySort && sortBy !== querySort) {
			setSort(querySort);
		} else if (defaultSort) {
			setSort(defaultSorting);
		} else if (sortBy && sortBy !== sort) {
			setSort(sortBy);
		} else {
			setSort(defaultSorting);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Select
			aria-label='Sort library and included assets & projects'
			className={classNameList.join(' ')}
			data-sort-for={sortFor || 'none'}
			displayEmpty
			onChange={handleSort}
			renderValue={(value) => `${value}`}
			sx={{
				svg: {
					color: 'primary.main',
				},
			}}
			value={startCase(sort) || ''}
		>
			{sortOptions.map((option) => (
				<MenuItem
					key={option}
					sx={{
						'&:hover': {
							backgroundColor: 'primary.main',
							color: 'text.primary',
							borderRadius: '20px',
						},
					}}
					value={option}
				>
					{option}
				</MenuItem>
			))}
		</Select>
	);
}
