/* eslint-disable no-nested-ternary */
import { findProjectThumbnailUrl } from '@mpx-sdk/helpers/assets';
import { matureWordsFilter } from '@mpx-sdk/helpers/input';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { projectExportsAtom, singleAssetViewAtom, userAtom } from '@mpx-sdk/shared/atoms';
import { type PublicAsset } from '@mpx-sdk/types';
import AssetCardExportTooltip from '@mpx-sdk/ui/components/assets/card/AssetCardExportTooltip';
import AssetThumbnail from '@mpx-sdk/ui/components/assets/card/AssetThumbnail';
import AssetCardOverlay from '@mpx-sdk/ui/components/assets/card/overlay/AssetCardOverlay';
import { Card, CardActionArea, CardMedia, CircularProgress, Stack, Typography } from '@mui/material';
import classNames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import { capitalize, isEmpty, isNumber } from 'lodash';
import Link from 'next/link';
import { type ReactElement, memo, useEffect, useState } from 'react';

interface AssetCardProps {
	/** The project data to display in the modal */
	projectData: PublicAsset;

	/** The thumbnail URL to display in the modal */
	thumbnailUrl?: string;

	/** Whether the delete button should appear on the asset card */
	deleteAsset?: boolean;

	/** Whether you want to hide the asset card's buttons */
	hideMediaOverlay?: boolean;

	/** Whether to force display the title and not let it be dependent on view style */
	forceDisplayTitle?: boolean;
}

/**
 * Display projects within the library
 * @returns {ReactElement} Asset card JSX element
 */
function AssetCard({
	deleteAsset,
	forceDisplayTitle = false,
	hideMediaOverlay,
	projectData,
	thumbnailUrl,
}: Readonly<AssetCardProps>): ReactElement {
	const currentUser = useAtomValue(userAtom);
	const responsiveView = UIHelper.isResponsiveView();
	const setSAVData = useSetAtom(singleAssetViewAtom);

	const thumbnailUrlState =
		thumbnailUrl ??
		findProjectThumbnailUrl((projectData?.projectFiles || projectData?.files) ?? [], projectData?.thumbnailUrl);
	// Following related to project exports
	const [loadingProgress, setLoadingProgress] = useState(0);
	const projectExports = useAtomValue(projectExportsAtom);
	/** Toggles the display of the export asset tooltip */
	const [openExportAssetTooltip, setOpenExportAssetTooltip] = useState(false);

	/** Generates the card media component for the asset card */
	function generateCardMedia(): ReactElement {
		if (projectData?.id && ['empty', 'export'].includes(projectData.id as string) && projectData?.type) {
			if (['continue', 'private'].includes(projectData.type)) {
				return (
					<CardMedia
						className='asset-thumbnail asset-thumbnail-empty'
						data-project-id={`project-thumbnail-${projectData?.id}-${projectData?.type}`}
						title='Empty slot'
					>
						<span className='asset-thumbnail-empty-text' />
					</CardMedia>
				);
			}

			if (projectData.type === 'export') {
				return (
					<CardMedia
						className='asset-thumbnail asset-thumbnail-empty'
						data-project-id={`project-thumbnail-${projectData?.id}-${projectData?.type}`}
						title='Exporting in progress ...'
					>
						<span className='asset-thumbnail-empty-text'>
							<CircularProgress
								value={loadingProgress}
								variant={
									loadingProgress <= 0 || loadingProgress >= 100 ? 'indeterminate' : 'determinate'
								}
							/>
						</span>
					</CardMedia>
				);
			}
		}

		return (
			<CardMedia
				aria-label={`Thumbnail for ${projectData?.description ?? projectData?.title ?? 'Untitled Project'}`}
				className='asset-thumbnail'
				data-project-id={`project-thumbnail-${projectData?.id}-${projectData?.type}`}
				sx={{
					position: 'relative',
					width: '100%',
					height: '100%',
				}}
				title={
					!isEmpty(projectData?.title)
						? !currentUser?.matureFilter
							? projectData?.title
							: matureWordsFilter(projectData?.title)
						: 'Untitled Project'
				}
			>
				<AssetThumbnail
					alt={projectData?.description ?? projectData?.title ?? 'Untitled Project'}
					src={thumbnailUrlState}
				/>
			</CardMedia>
		);
	}

	useEffect(() => {
		if (projectData.destination) {
			if (projectExports?.progress && loadingProgress !== projectExports?.progress) {
				setLoadingProgress(projectExports?.progress || 0);

				if (!hideMediaOverlay && projectData?.destination && (projectExports?.progress || 0)) {
					setOpenExportAssetTooltip(true);

					if (projectData.id) {
						DataLayer.triggerMeasurementEvent('assetEvent', {
							destination: projectData.destination,
							event_name: `export_${projectData.destination}_location`,
							title: projectData.title,
							userId: currentUser?.id,
							projectId: projectData.id,
						});
					}
				}
			} else {
				setLoadingProgress(0);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectExports, projectData]);

	return (
		<AssetCardExportTooltip
			describeChild
			onClick={() => setOpenExportAssetTooltip(false)}
			onClose={() => setOpenExportAssetTooltip(false)}
			open={openExportAssetTooltip}
			title={projectData?.tooltip}
		>
			<Card
				className={classNames('asset-card', {
					'asset-card-export-overlay': projectData?.destination && loadingProgress >= 100,
				})}
				sx={{
					'&:hover': {
						'.asset-media-overlay': {
							display: 'flex',
						},
					},
					height: '100%',
				}}
			>
				<Link
					href={
						isNumber(projectData?.id)
							? `/library/${
									!isEmpty(projectData.title)
										? `${projectData.title.trim().replace(/[\s\W-]+/g, '-')}-`
										: ''
								}${projectData.id}`
							: `/storage/${projectData?.id}`
					}
					onClick={(e) => {
						if (projectData?.id === 'empty') {
							e.preventDefault();
						}

						if (!isEmpty(projectData)) {
							setSAVData(projectData);
						}
					}}
					shallow
				>
					<CardActionArea className='asset-card-action-area'>
						{generateCardMedia()}

						<Stack
							alignItems='flex-start'
							className={classNames('asset-media-overlay asset-media-overlay-title', {
								'asset-media-overlay-title-desktop': responsiveView,
							})}
							direction='row'
							justifyContent='flex-start'
							spacing={0.5}
							sx={{
								display: forceDisplayTitle || !responsiveView ? 'flex' : 'none',
								width: '100%',
							}}
						>
							<Typography
								sx={
									responsiveView
										? {
												maxWidth: '56%',
											}
										: undefined
								}
							>
								{projectData.title ||
									(projectData?.type &&
										['continue', 'private'].includes(projectData?.type) &&
										'Empty Slot') ||
									'Untitled Project'}
							</Typography>

							{projectData?.metadata?.genAIData?.status &&
								!['complete'].includes(projectData?.metadata?.genAIData?.status) && (
									<Typography sx={{ maxWidth: '56%', color: 'text.subtext' }}>
										{projectData?.metadata?.genAIData?.status === 'failed'
											? `Failed to process...`
											: projectData?.metadata?.genAIData?.status === 'processing'
												? `Processing${
														projectData?.metadata?.genAIData?.progress
															? ` ${Number(
																	projectData?.metadata?.genAIData?.progress,
																).toFixed(1)}%`
															: '...'
													}`
												: `${capitalize(projectData?.metadata?.genAIData?.status)}...`}
									</Typography>
								)}
						</Stack>
					</CardActionArea>
				</Link>

				{!hideMediaOverlay && projectData?.id !== 'empty' && projectData.type !== 'export' && (
					<AssetCardOverlay deleteAsset={deleteAsset} projectData={projectData} />
				)}
			</Card>
		</AssetCardExportTooltip>
	);
}

export default memo(AssetCard);
