'use client';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { useServerInsertedHTML } from 'next/navigation';
import React, { type PropsWithChildren } from 'react';
import { darkTheme as theme } from '../../themes/dark';

// This implementation is from emotion-js
// https://github.com/emotion-js/emotion/issues/2928#issuecomment-1319747902
export default function ThemeRegistry(props: PropsWithChildren<{ options: Parameters<typeof createCache>[0] }>) {
	const { options, children } = props;

	const [{ cache, flush }] = React.useState(() => {
		const cache = createCache(options);
		cache.compat = true;
		const prevInsert = cache.insert;
		let inserted: string[] = [];
		cache.insert = (...args) => {
			const serialized = args[1];
			if (cache.inserted[serialized.name] === undefined) {
				inserted.push(serialized.name);
			}
			return prevInsert(...args);
		};
		const flush = () => {
			const prevInserted = inserted;
			inserted = [];
			return prevInserted;
		};
		return { cache, flush };
	});

	useServerInsertedHTML(() => {
		const names = flush();

		if (names.length === 0) {
			return null;
		}

		const styles = names.map((name) => cache.inserted[name]).join('');

		return (
			<style
				key={cache.key}
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					__html: styles,
				}}
				data-emotion={`${cache.key} ${names.join(' ')}`}
			/>
		);
	});

	return (
		<CacheProvider value={cache}>
			<ThemeProvider theme={createTheme(theme)}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</CacheProvider>
	);
}
