'use client';

import { UIHelper } from '@mpx-sdk/helpers/ui';
import { AlertCircleIcon, AlertTriangleIcon } from '@mpx-sdk/images';
import { activeStepAtom, forcefullyCloseTooltipAtom } from '@mpx-sdk/shared/atoms';
import { nanoidLength } from '@mpx-sdk/shared/utils';
import { CloseOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { debounce, isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import { type ReactElement, type ReactNode, cloneElement, useEffect, useState } from 'react';

export interface ToolTipProps {
	title?: string;
	paragraphs?: (string | ReactNode)[];
	children?: ReactNode;
	images?: string[];
	showGotItButton?: boolean;
	showCloseButton?: boolean;
	showAlertIcon?: boolean; // Add a new prop for showing the alert icon
	alertIconText?: (string | ReactNode)[] | undefined; // Add a new prop for the alert icon text
	alertIconPara?: (string | ReactNode)[] | undefined;
	interactive?: boolean;
}

export default function IconTooltip({
	title,
	paragraphs,
	images,
	showGotItButton = true,
	showCloseButton = false,
	showAlertIcon = false, // Default value is false
	alertIconText, // Default value is an empty string
	alertIconPara,
	children,
	interactive = false,
}: Readonly<ToolTipProps>): ReactElement {
	const [forceCloseTooltips, setForceCloseTooltips] = useAtom(forcefullyCloseTooltipAtom);
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const activeStep = useAtomValue(activeStepAtom);
	const responsiveView = UIHelper.isResponsiveView();
	const tooltipId = nanoid();

	const handleTooltipToggle = () => {
		setIsTooltipOpen(!isTooltipOpen);

		setForceCloseTooltips(false);
	};

	const handleCloseTooltip = () => {
		setIsTooltipOpen(false);

		setForceCloseTooltips(false);
	};

	const debounceCloseTooltip = debounce(handleCloseTooltip, 100);

	const isTextField = children && (children as React.ReactElement).type === TextField;

	const child = !isTextField ? (
		<IconButton aria-label='Tooltip' onClick={handleTooltipToggle}>
			<AlertCircleIcon
				sx={{
					fill: (theme) => theme.palette.icons.greyed,
					height: '1.2rem',
					width: '1.2rem',
				}}
			/>
		</IconButton>
	) : (
		cloneElement(children as ReactElement, {
			onFocus: () => setIsTooltipOpen(true),
			onBlur: (e) => {
				if (!document.getElementById(tooltipId)?.contains(e?.relatedTarget)) debounceCloseTooltip();
			},
		})
	);

	useEffect(() => {
		if (forceCloseTooltips || activeStep) {
			debounceCloseTooltip();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forceCloseTooltips, activeStep]);

	if (isEmpty(paragraphs)) {
		return child;
	}

	return (
		<Tooltip
			aria-label='Tooltip'
			arrow
			describeChild
			disableHoverListener
			disableTouchListener
			id={tooltipId}
			onClose={() => {
				if (!interactive) {
					setIsTooltipOpen(false);
				}
			}}
			open={isTooltipOpen}
			placement={responsiveView ? 'left' : undefined}
			title={
				<Stack
					spacing={2}
					sx={{
						margin: '5% 3% 3%',
						maxHeight: '80vh',
						overflowY: 'auto',
					}}
				>
					{showCloseButton && (
						<IconButton
							aria-label='Close tooltip'
							onClick={debounceCloseTooltip}
							sx={{ position: 'absolute', top: 0, right: 0, padding: '3px' }}
						>
							<CloseOutlined />
						</IconButton>
					)}

					{title && <Box sx={{ fontWeight: 'bold', marginBottom: '8px', fontSize: '20px' }}>{title}</Box>}

					{paragraphs?.map((paragraph) => (
						<Box key={`tooltip-paragraph-${nanoidLength(4)}`} sx={{ color: 'text.subtext' }}>
							{paragraph}
						</Box>
					))}

					{images &&
						Array.isArray(images) &&
						images.map((imageUrl, index) => (
							<Box key={`tooltip-image-${nanoidLength(4)}`}>
								<img alt='ToolTip' height={150} src={imageUrl} width='100%' />
								{index < images.length - 1 && <br />}
							</Box>
						))}

					{showAlertIcon &&
						alertIconText && ( // Conditionally render the alert icon and text
							<>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										marginBottom: '0px',
										margin: '1px',
									}}
								>
									<AlertTriangleIcon
										sx={{
											fill: 'none !important',
										}}
									/>

									{/* Render the alert icon text */}
									<Box sx={{ marginLeft: '8px', color: 'text.subtext' }}>{alertIconText} </Box>
								</Box>

								<Box
									sx={{
										color: 'text.subtext',
									}}
								>
									{alertIconPara}
								</Box>
							</>
						)}

					{showGotItButton && (
						<Button
							aria-label='Confirm'
							onClick={() => setIsTooltipOpen(false)}
							sx={{ marginTop: 'auto' }}
							variant='contained'
						>
							Got it
						</Button>
					)}
				</Stack>
			}
		>
			{child}
		</Tooltip>
	);
}
