import { PlayIcon } from '@mpx-sdk/images';
import { Backdrop, Box, Button, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { type ReactElement } from 'react';

/** Props for the VideoCard component. */
interface VideoCardProps {
	/** The URL of the video thumbnail. */
	thumbnailUrl: string;
	/** The title of the video. */
	title?: string;
	/** A callback function that is called when the video is played. */
	onPlay?: () => void;
}

/** UI Component to display a video card */
// eslint-disable-next-line no-unused-vars
export default function VideoCard({ thumbnailUrl, title, onPlay }: VideoCardProps): ReactElement {
	return (
		<Stack alignItems='flex-start' direction='column' justifyContent='center' spacing={1}>
			<Box
				sx={{
					position: 'relative',
					width: '100%',
					height: '225px',
				}}
			>
				<Image
					alt={title ?? 'Video thumbnail'}
					fill
					src={thumbnailUrl}
					style={{
						borderRadius: '5px',
					}}
				/>

				<Backdrop open sx={{ position: 'absolute' }} />
				<Button
					aria-label={`Play video: ${title}`}
					color='secondary'
					onClick={onPlay}
					sx={{
						borderRadius: '50%',
						width: '60px',
						height: '60px',
						// Center the button relative to the parent container
						left: '50%',
						position: 'absolute',
						top: '50%',
						transform: 'translate(-50%, -50%)',
						'&:hover': {
							backgroundColor: 'primary.main',
							svg: {
								color: 'primary.contrastText',
							},
						},
					}}
					variant='contained'
				>
					<PlayIcon style={{ fontSize: 28 }} />
				</Button>
			</Box>

			{title && (
				<Typography
					sx={{
						fontSize: 'clamp(1rem, 1.5vw, 1.5rem)',
						fontWeight: 700,
						letterSpacing: '-0.02em',
						lineHeight: '40px',
						textAlign: 'left',
					}}
				>
					{title}
				</Typography>
			)}
		</Stack>
	);
}
